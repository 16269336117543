const T = {
	createServiceMainTitle: 'Solicitud Servicios',
	createFormSteps: [
		{
			label: 'Paso 01',
			secondaryLabel: 'Buscar cliente',
		},
		{
			label: 'Paso 02',
			secondaryLabel: 'Completar detalles',
		},
		{
			label: 'Paso 03',
			secondaryLabel: 'Locación',
		},
	],
	navigationButtons: {
		back: 'Regresar',
		next: 'Continuar',
		submit: 'Enviar Solicitud',
	},
	searchContract: {
		searchContractInputDefaultLabel: 'Número de teléfono',
		searchContractTooltip: 'Busca el cliente para que los detalles se completen automáticamente',
		searchContractBtn: 'Buscar cliente',
		skipSearchContractBtn: 'Continuar sin búsqueda',
		errors: {
			missingAccountOrBranch: 'Cuenta o país no encontrado para obtener contratos del cliente',
			errorFetchingContracts: 'Algo salió mal al obtener los contratos del cliente. Por favor intente nuevamente',
			noContractFound: 'No se ha encontrado ningun contrato para este cliente. Por favor intente cambiando la busqueda',
		},
	},
	serviceDetails: {
		sectionTitleClient: 'Datos del Cliente',
		sectionTitleVehicle: 'Datos del Vehículo',
		sectionTitleService: 'Datos del Servicio',
		clientName: { id: 'name', label: 'Name (Nombre)' },
		clientLastName: {
			id: 'lastName',
			label: 'Last Name (Apellido)',
		},
		clientMaidenName: { id: 'maidenName', label: 'Maiden Name (Apellido Materno)' },
		clientDriverName: { id: 'driverName', label: 'Driver Name (Nombre Conductor)' },
		clientPhone: {
			id: 'phone',
			label: 'Phone (Teléfono)',
		},
		clientSecondaryPhone: {
			id: 'secondaryPhone',
			label: 'Secondary Phone (Teléfono Secundario)',
		},
		clientEmail: { id: 'email', label: 'Email (Correo Electrónico)' },
		VehicleType: { id: 'type', label: 'Vehicle Type' },
		vehicleMake: { id: 'make', label: 'Make (Marca)' },
		vehicleOtherMake: {
			id: 'otherMake',
			label: 'Otra marca',
		},
		vehicleModel: {
			id: 'model',
			label: 'Model (Sub Marca)',
		},
		vehicleOtherModel: {
			id: 'otherModel',
			label: 'Otro modelo',
		},
		vehicleYear: { id: 'year', label: 'Year (Modelo)' },
		vehicleArmorLevel: {
			id: 'armorLevel',
			label: 'Armor Level (Nivel de Blindaje)',
		},
		vehicleColor: { id: 'color', label: 'Color (Color)' },
		vehiclePlate: { id: 'plate', label: 'Plate (Placa)' },
		vehicleKavakStock: { id: 'externalId', label: 'External ID (Stock)' },
		vehiclePolicyNumber: {
			id: 'policyNumber',
			label: 'Policy Number (Número de Póliza)',
		},
		vehicleKavakId: {
			id: 'kavakId',
			label: 'Vehicle ID (ID Vehículo)',
		},
		vehicleExternalId: {
			id: 'externalId',
			label: 'External ID (Siniestro o Reporte)',
		},
		externalIdVanti: {
			id: 'externalId',
			label: 'External ID (ODS)',
		},
		vehicleVin: {
			id: 'vin',
			label: 'VIN',
		},
		serviceSituation: {
			id: 'situation',
			label: 'Situation (Tipo de Servicio)',
		},
		serviceCarrierName: {
			id: 'carrierName',
			label: '¿Quién Entrega?',
		},
		serviceCarrierPhone: {
			id: 'carrierPhone',
			label: 'Teléfono Entrega',
		},
		serviceReceiverName: {
			id: 'receiverName',
			label: '¿Quién Recibe?',
		},
		serviceReceiverPhone: {
			id: 'receiverPhone',
			label: 'Teléfono Recibe',
		},
		serviceComments: { id: 'comments', label: 'Comments (Comentarios)' },
		visitType: { id: 'visitType', label: 'Visit (Tipo de Cita)' },
		noveltyType: { id: 'noveltyType', label: 'Novedad C4C' },
		city: { id: 'city', label: 'City (Ciudad)' },
		date: { id: 'date', label: 'Date (Fecha)' },
		time: { id: 'time', label: 'Time (Hora)' },
		ticketNumberVanti: { id: 'ticketNumber', label: 'Ticket #' },
	},
	locations: {
		locationTitle: 'Ubicación',
		originTitle: 'Origen',
		destinationTitle: 'Destino',
		optionalInputSeparator: 'ó',
		locationAutoCompleteError: 'Se ha producido un error al buscar la ubicación.',
		situationAutocomplete: { id: 'input-situation-autocomplete', label: 'Buscar localización, Coordenadas' },
		situationFixedLocation: { id: 'input-situation-fixed-location', label: 'Patio KAVAK' },
		destinationAutocomplete: { id: 'input-destination-autocomplete', label: 'Buscar localización, Coordenadas' },
		destinationFixedLocation: { id: 'input-destination-fixed-location', label: 'Patio KAVAK' },
		errorMalformedLocation: 'Parece que se produjo un error obteniendo la ubicación. Por favor intente nuevamente',
		referenceAddress: { id: 'referenceAddress', label: 'Incluir detalle de Dirección del servicio' },
	},
};

export default T;
