// External imports
import localforage from 'localforage';
import { ClientDataI, ServiceDataI, ServiceLocationsDataI, VehicleDataI } from 'views/CreateService/types';
// Local imports
import { HELIOS_STATUS, SERVICE_METADATA_SOURCE_DATA, SERVICE_SOURCE, USER_TYPE, VANTI_ID } from '../utils/constants';
import { ClientContract, ServiceRequestPostParams } from './interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const storeAccountAuth = (user: any): void => {
	localforage.setItem(USER_TYPE.USER, user);
	localforage.setItem('userType', USER_TYPE.USER);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const storeProviderAuth = (provider: any): void => {
	localforage.setItem(USER_TYPE.PROVIDER, provider);
	localforage.setItem('userType', USER_TYPE.PROVIDER);
};

export const storeAuth = (token: string, expires: string): void => {
	localforage.setItem('token', token);
	localforage.setItem('expires', expires);
};

export const getAuthToken = async (): Promise<any> => {
	const token = await localforage.getItem('token');
	return token;
};

export const transformSearchCriteria = (searchCriteria?: string[]): string => {
	if (!searchCriteria || searchCriteria?.length === 0) {
		return 'Número de teléfono';
	} else {
		const parsedSearchCriteria = searchCriteria.map(
			(searchCriteriaValue) => SEARCH_CRITERIA_ES[searchCriteriaValue] || searchCriteriaValue
		);
		return parsedSearchCriteria.join(', ');
	}
};

const SEARCH_CRITERIA_ES: Record<string, string> = {
	Plate: 'Placa',
	Phone: 'Teléfono',
	Policy: 'Num. de Poliza',
	VIN: 'VIN',
	'Full name': 'Nombre',
	'ID Card': 'Cedula #',
};

export const dispatchServiceParseParams = ({
	client,
	service,
	vehicle,
	locations,
	contract,
	accountId,
}: {
	client: ClientDataI;
	service: ServiceDataI;
	vehicle: VehicleDataI;
	locations: ServiceLocationsDataI;
	contract?: ClientContract;
	accountId: string;
}): ServiceRequestPostParams => {
	const serviceRequestParams: ServiceRequestPostParams = {
		account_id: accountId,
		serviceSource: SERVICE_SOURCE,
		sourceData: SERVICE_METADATA_SOURCE_DATA,
		clientName: client.name,
		clientLastName: client.lastName,
		clientPhone1: client.phone,
		clientPhone2: client.secondaryPhone,
		clientEmail: client.email,
		situation: service.situation?.name,
		policyNumber: vehicle.policyNumber,
		policyType: accountId === VANTI_ID ? 'VantiHogar' : undefined,
		vehVin: vehicle.vin,
		vehColor: vehicle.color,
		vehMake: vehicle.otherMake || vehicle.make,
		vehModel: vehicle.otherModel || vehicle.model,
		vehYear: vehicle.year?.toString(),
		vehPlate: vehicle.plate,
		vehicleType: vehicle.type,
		customerContract: contract,
		sitLatitude: locations.situation?.lat,
		sitLongitude: locations.situation?.lng,
		sitAddress: locations.situation?.address,
		sitPlaceId: locations.situation?.placeId,
		sitReferenceAddress: locations.referenceAddress ?? '',
		destLatitude: locations.destination?.lat,
		destLongitude: locations.destination?.lng,
		destAddress: locations.destination?.address,
		destPlaceId: locations.destination?.placeId,
		status: HELIOS_STATUS.HOLD_INSPECTION,
		externalId: vehicle.externalId,
		externalIdCard: accountId === VANTI_ID ? vehicle.policyNumber : '',
		notes: transformToNotes({
			armorLevel: vehicle.armorLevel,
			kavakId: vehicle.kavakId,
			carrierName: service.carrierName,
			carrierPhone: service.carrierPhone,
			receiverName: service.receiverName,
			receiverPhone: service.receiverPhone,
			comments: service.comments,
			vip: service.vip,
			visitType: service.visitType,
			noveltyType: service.noveltyType,
			city: service.city,
			date: service.date,
			time: service.time,
			referenceAddress: locations.referenceAddress as string,
			ticketNumber: service.ticketNumber as string,
		}),
		driverName: client.driverName,
		vip: service.vip,
	};

	return serviceRequestParams;
};

const transformToNotes = ({
	armorLevel,
	kavakId,
	carrierName,
	carrierPhone,
	receiverName,
	receiverPhone,
	comments,
	vip,
	visitType,
	noveltyType,
	city,
	date,
	time,
	referenceAddress,
	ticketNumber,
}: {
	armorLevel: string;
	kavakId: string;
	carrierName: string;
	carrierPhone: string;
	receiverName: string;
	receiverPhone: string;
	comments: string;
	vip: boolean;
	visitType?: string;
	noveltyType?: string;
	city: string;
	date: string;
	time: string;
	referenceAddress: string;
	ticketNumber: string;
}): string => {
	let notes = '';
	if (armorLevel) {
		notes += `Nivel de armadura: ${armorLevel}\n`;
	}
	if (kavakId) {
		notes += `ID Kavak: ${kavakId}\n`;
	}
	if (carrierName) {
		notes += `Nombre del transportista: ${carrierName}\n`;
	}
	if (carrierPhone) {
		notes += `Teléfono del transportista: ${carrierPhone}\n`;
	}
	if (receiverName) {
		notes += `Nombre del receptor: ${receiverName}\n`;
	}
	if (receiverPhone) {
		notes += `Teléfono del receptor: ${receiverPhone}\n`;
	}
	if (comments) {
		notes += `Comentarios: ${comments}\n`;
	}
	if (vip) {
		notes += `Cuenta VIP: ${vip ? 'Sí' : 'No'}\n`;
	}
	if (visitType) {
		notes += `Tipo de Visita: ${visitType}\n`;
	}
	if (noveltyType) {
		notes += `Novedad C4C: ${noveltyType}\n`;
	}
	if (city) {
		notes += `Ciudad: ${city}\n`;
	}
	if (date) {
		notes += `Fecha: ${date}\n`;
	}
	if (time) {
		notes += `Hora: ${time}\n`;
	}
	if (referenceAddress) {
		notes += `Dirección: ${referenceAddress}\n`;
	}
	if (ticketNumber) {
		notes += `Ticket #: ${ticketNumber}\n`;
	}
	return notes;
};
